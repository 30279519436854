import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import {AgmCoreModule} from "@agm/core";
import { KontaktComponent } from './kontakt/kontakt.component';
import { SluzbyComponent } from './sluzby/sluzby.component';
import { CennikComponent } from './cennik/cennik.component';
import { TechnologieComponent } from './technologie/technologie.component';
import { FooterComponent } from './footer/footer.component';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LandingPageComponent,
    KontaktComponent,
    SluzbyComponent,
    CennikComponent,
    TechnologieComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA9Is9qPNFcH-yAnMtAq0KafuUcAVGZdXA'
    }),
    MatTabsModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatInputModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
