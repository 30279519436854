import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kontakt',
  templateUrl: './kontakt.component.html',
  styleUrls: ['./kontakt.component.scss']
})
export class KontaktComponent implements OnInit {

  lat = 48.759934617847286;
  lng = 17.827930855822178;

  workingHours: any[] = [
    { name: 'Pondelok', hour: '8:00 - 14:30' },
    { name: 'Utorok', hour: '8:00 - 14:30' },
    { name: 'Streda', hour: '11:00 - 17:30' },
    { name: 'Štvrtok', hour: '8:00 - 14:30' },
    { name: 'Piatok', hour: '8:00 - 12:00' },
    { name: 'Sobota', hour: 'Zatvorené' },
    { name: 'Nedeľa', hour: 'Zatvorené' },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
