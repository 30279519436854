import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Route, Router} from "@angular/router";

@Component({
  selector: 'app-sluzby',
  templateUrl: './sluzby.component.html',
  styleUrls: ['./sluzby.component.scss']
})
export class SluzbyComponent implements OnInit, AfterViewInit {

  @ViewChild('sedation') sedation;
  @ViewChild('babyTeeth') babyTeeth;
  @ViewChild('dentalFilling') dentalFilling;
  @ViewChild('implement') implement;
  @ViewChild('pineTeeth') pineTeeth;
  @ViewChild('white') white;

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.route.queryParams.subscribe(params => {
      this.scrollToSection(params.target);
    });
  }


  scrollToSection(target) {
    switch (target) {
      case 'sedation': {
        this.sedation.nativeElement.scrollIntoView({behavior: "smooth"});
        break;
      }
      case 'dentalFilling': {
        this.dentalFilling.nativeElement.scrollIntoView({behavior: "smooth"});
        break;
      }
      case 'implement': {
        this.implement.nativeElement.scrollIntoView({behavior: "smooth"});
        break;
      }
      case 'pineTeeth': {
        this.pineTeeth.nativeElement.scrollIntoView({behavior: "smooth"});
        break;
      }
      case 'white': {
        this.white.nativeElement.scrollIntoView({behavior: "smooth"});
        break;
      }
    }
  }

  openCennik() {
    this.router.navigate(['/cennik']);
  }

}
