<div class="landing-page grow-1">
  <div class="w-full grow-1 landing-top">
    <div *ngIf="responsive" class="contact-on-landing grow-1 w-full row">
      <br>
      <div class="col m4 justify-end flex align-center pl-48">
        <div class="col">
          <i class="material-icons icon">phone</i>
        </div>
        <div class="col" style="font-weight: bold">
          <div class="flex grow-1">
            <a href="tel+421911970706">0911 970 706</a>
          </div>
        </div>
      </div>
      <div class="col m5 justify-center flex align-center pl-48 pt-24">
        <div class="col">
          <i class="material-icons icon">home</i>
        </div>
        <div class="col">
          <div class="flex grow-1">
            <a href="https://www.google.com/maps/place/Levo%C4%8Dsk%C3%A1+335,+064+01+Star%C3%A1+%C4%BDubov%C5%88a/@49.3014054,20.6888883,17z/data=!3m1!4b1!4m5!3m4!1s0x473e12a9d6888103:0xd953ef0b70d3c80a!8m2!3d49.3014054!4d20.691077"
               target="_blank" style="font-weight: bold"
            >1. mája 9, Nové Mesto nad Váhom </a>
          </div>
        </div>
      </div>
      <div class="col m3 justify-start flex align-center pl-48 pt-32">
        <div class="col">
          <i class="material-icons icon">facebook</i>
        </div>
        <div class="col">
          <div class="flex grow-1">
            <a href="https://www.facebook.com/" target="_blank"  style="font-weight: bold">TARASDENT, s.r.o</a>
          </div>
        </div>
      </div>
    </div>

<!--    <div class="flex w-full justify-center main-title">-->
<!--      <h4>Zubná ambulancia</h4>-->
<!--    </div>-->
    <div class="flex row w-full h-full">
      <div *ngIf="!responsive" class="flex align-center justify-start col m6">

        <div style="width: 60%">
          <h4 class="landing-side-text-family">ZDRAVÝ ÚSMEV</h4>
          <h4 class="landing-side-text-family pl-48">PRE CELÚ RODINU</h4>
        </div>
      </div>
      <div class="flex align-center justify-center col m6 s12 p-0">
        <div (click)="navigateToContact()" class="landing-side-order pointer">
          <h4 class="landing-side-order__text">OBJEDNAJTE SA TERAZ</h4>
          <div class="flex w-full grow-1 justify-center">
<!--            <mat-icon class="landing-side-order__icon">event</mat-icon>-->

            <i class="landing-side-order__icon material-icons medium">event</i>
          </div>
        </div>
      </div>
    </div>
  </div>

<!--  <div class="flex w-full grow-1 align-center justify-center" style="height: 4rem">-->
<!--  </div>-->

  <div class="thumb_title flex w-full grow-1 align-center justify-center">
    <h4 style="text-transform: uppercase" (click)="scroll(services)">Vyberte si z našich služieb</h4>
  </div>

  <div #services class="thumbnails inline-flex">
    <div (click)="redirectTo('pineTeeth')" class="thumb-item thumb-item__b flex ">
      <div class="thumb-item__title w-full flex align-center justify-center">
        <span>Bolesť zubov</span>
      </div>
    </div>
    <div (click)="redirectTo('dentalFilling')" class="thumb-item thumb-item__e flex">
      <div class="thumb-item__title w-full flex align-center justify-center">
        <span>Dentálny fíling</span>
      </div>
    </div>

    <div (click)="redirectTo('implement')" class="thumb-item thumb-item__a col flex">
      <div class="thumb-item__title w-full flex align-center justify-center">
        <span>Zubné Implantácie</span>
      </div>
    </div>

    <div (click)="redirectTo('sedation')" class="thumb-item thumb-item__c col flex">
      <div class="thumb-item__title w-full flex align-center justify-center">
        <span>Anestézia</span>
      </div>
    </div>

    <div (click)="redirectTo('babyTeeth')" class="thumb-item thumb-item__f col flex">
      <div class="thumb-item__title w-full flex align-center justify-center">
        <span>Detské ošetrenia</span>
      </div>
    </div>
  </div>


  <div *ngIf="!responsive" class="information">
    <div class="flex grow-1 justify-center mt-48 pt-24">
      <div (click)="scroll(map)" class="information_hov flex align-center pr-24">
        <!--      <i *ngIf="scrollDown" class="material-icons icon">arrow_drop_up</i>-->
        <i *ngIf="true" class="material-icons icon">arrow_drop_down</i>
      </div>
      <div (click)="scroll(map)" class="information_hov pointer flex align-center pt-24">
        <h5 class="pb-8" style="text-transform: uppercase">Tešíme sa na vás</h5>
      </div>
    </div>
  </div>
</div>


<div>
  <div class="w-full row">
    <div class="col s12 m6">
      <div class="contact-box">
        <div class="contact justify-start flex align-center pt-24">
          <div class="col">
            <i class="material-icons icon small">phone</i>
          </div>
          <div class="col" style="font-weight: bold">
            <a href="tel:0911970706">0911 970 706</a>
          </div>
        </div>
        <div class="contact justify-start flex align-center pt-24">
          <div class="col">
            <i class="material-icons icon small">drafts</i>
          </div>
          <div class="col" style="font-weight: bold">
            <div class="flex grow-1">
              <a href="mailto:tarasdentsro@gmail.com"><span class="contact__text">tarasdentsro@gmail.com</span></a>
            </div>
          </div>
        </div>
        <br>
        <div class="contact justify-start flex align-center ">
          <div class="col" style="font-weight: bold">
            <i class="material-icons icon small">facebook</i>
          </div>
          <div class="col" style="font-weight: bold">
            <div class="flex grow-1">
              <a href="https://www.facebook.com" target="_blank"><span
                class="contact__text">TARASDENT, s.r.o</span></a>
            </div>
          </div>
        </div>
        <br>
        <div class="contact justify-start flex align-center">
          <div class="col">
            <i class="material-icons icon small">home</i>
          </div>
          <div class="col" style="font-weight: bold">
            <div class="flex grow-1">
              <a href="https://maps.app.goo.gl/kixodihx6Z6R1t698" target="_blank"
              >Nové Mesto nad Váhom<br><span class="right">1. mája 9</span></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="map-title-box flex w-full mr-8 mt-12 justify-center">
      <h2 class="map-title">
        <a href="https://maps.app.goo.gl/kixodihx6Z6R1t698" target="_blank"
        ><mat-icon matPrefix style="padding-right: 2rem">place</mat-icon>
        Kde nás nájdete:</a>
      </h2>
    </div>

    <div #map class="col m6 s12 h-full flex justify-center">
      <div class="agm-map-box">
        <agm-map [latitude]="lat" [longitude]="lng" [zoom]="19"></agm-map>
      </div>
      </div>
  </div>
</div>
