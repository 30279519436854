<div class="container">
  <div class="cennik-title">
    <div class="title flex w-full justify-center">
      <h4>Cenník</h4>
    </div>
<!--    <div class="flex w-full justify-center">-->
      <!--      <p>Objednať sa u nás môžete <span class="red-text">mailom</span> alebo <span class="red-text">telefonicky</span>-->
      <!--        kedykoľvek počas pracovných hodín.</p>-->
<!--    </div>-->
  </div>
  <div>

    <mat-tab-group [(selectedIndex)]="selectedIndex">
      <mat-tab *ngFor="let cennik of cennik" label="{{cennik.title}}">
        <table class="striped table">
          <thead>
          <tr>
            <th>Výkon</th>
            <th>Doplatok k ZP</th>
            <th>Priama platba</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor=" let vykon of cennik.procedures">
            <td style="padding-left: 1rem">{{ vykon.process }} </td>
            <td>{{ vykon.zpPrice }} <span *ngIf="vykon.zpPrice !== '-'">€</span></td>
            <td>{{ vykon.price }} <span *ngIf="vykon.price !== '-'">€</span></td>
          </tr>
          </tbody>
        </table>


      </mat-tab>
    </mat-tab-group>
  </div>
</div>
