import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  responsive = false;

  lat = 48.759934617847286;
  lng = 17.827930855822178;

  scrollDown = false;

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.checkResizing(event.target.innerWidth);
  }


  constructor(private router: Router) {
  }

  ngOnInit(): void {
    this.checkResizing(window.innerWidth);
  }

  redirectTo(target): void {
    this.router.navigate(['/sluzby'], {queryParams: {target: target}});
  }

  navigateToContact(): void {
    this.router.navigate(['/kontakt']);

  }

  checkResizing(screenWidth): void {
    this.responsive = screenWidth < 860;
  }

  scroll(el): void {
    el.scrollIntoView({behavior: 'smooth'});
    this.scrollDown = !this.scrollDown;
  }

}
