export interface Cennik {
  title: string;
  procedures: {process: string, zpPrice: string, price: string}[];
}

export const cennik: Cennik[] = [
  {
    title: 'Vyšetrenie, prevencia, administratíva',
    procedures: [
      {
        process: 'Vstupné komplexné stomatologické vyšetrenie',
        zpPrice: '-',
        price: '25.00'
      },
      {
        process: 'Preventívna prehliadka',
        zpPrice: '-',
        price: '20.00'
      },
      {
        process: 'Preventívna stomatologická prehliadka v tehotenstve (platí 2x počas tehotenstva)',
        zpPrice: '-',
        price: '20.00'
      },
      {
        process: 'Vyžiadané akútne ošetrenie',
        zpPrice: '25.00',
        price: '35.00'
      },
      {
        process: 'Evidencia nového pacienta',
        zpPrice: '10.00',
        price: '10.00'
      },
      {
        process: 'Konzultácia',
        zpPrice: '20.00',
        price: '20.00'
      },
      {
        process: 'Konzultácia s kalkuláciou',
        zpPrice: '30.00',
        price: '30.00'
      },
      {
        process: 'Krátky administratívny úkon',
        zpPrice: '10.00',
        price: '10.00'
      }
    ]
  },
  {
    title: 'Anestézia',
    procedures: [
      {
        process: 'Povrchová anestézia',
        zpPrice: '5,00',
        price: '5.00'
      },
      {
        process: 'Injekčná anestézia',
        zpPrice: '10,00',
        price: '15.00'
      },
      {
        process: 'Intraoseálna anestézia',
        zpPrice: '15,00',
        price: '15.00'
      },
      {
        process: 'Extrakcia mliečneho zuba',
        zpPrice: '15.00',
        price: '20,00'
      },
      {
        process: 'Extrakcia trvalého jednokoreňového zuba',
        zpPrice: '35,00',
        price: '45.00'
      },
      {
        process: 'Extrakcia trvalého viackoreňového zuba',
        zpPrice: '45,00',
        price: '60.00'
      },
      {
        process: 'Komplikovaná chirurgická extrakcia',
        zpPrice: '70,00',
        price: '120.00'
      },
      {
        process: 'Zastavenie poextrakčného krvácania',
        zpPrice: '15,00',
        price: '20.00'
      },
      {
        process: 'Extrakcia 8 zuba',
        zpPrice: '120,00',
        price: '120,00'
      },
      {
        process: 'Komplikovana chirurgická extrakcia 8 zuba',
        zpPrice: '200,00',
        price: '200,00'
      },
      {
        process: 'Ošetrenie extr. rany',
        zpPrice: '10,00',
        price: '15,00'
      },
      {
        process: 'Gelatamp',
        zpPrice: '15,00',
        price: '15,00'
      },
      {
        process: 'Parasorb Cone Genta',
        zpPrice: '35,00',
        price: '35,00'
      },
      {
        process: 'Parasorb Cone',
        zpPrice: '40,00',
        price: '40,00'
      }
    ]
  },
  {
    title: 'Akútne paliatívne endodontické ošetrenie, trepanácia zuba, umŕtvenie',
    procedures: [
      {
        process: 'Jednokoreňový zub',
        zpPrice: '55,00',
        price: '-'
      },
      {
        process: 'Viackoreňový zub',
        zpPrice: '70,00',
        price: '-'
      },
      {
        process: 'Jednoplôšková výplň  - skloionomer',
        zpPrice: '50.00',
        price: '65.00'
      },
      {
        process: 'Jednoplôšková výplň  - fotokompozit',
        zpPrice: '55.00',
        price: '70.00'
      },
      {
        process: 'Dvojplôšková výplň  - skloionomer',
        zpPrice: '55.00',
        price: '70.00'
      },
      {
        process: 'Dvojplôšková výplň  - fotokompozit',
        zpPrice: '60.00',
        price: '75.00'
      },
      {
        process: 'Trojplôšková výplň  - skloionomer',
        zpPrice: '70.00',
        price: '90.00'
      },
      {
        process: 'Trojplôšková výplň  - fotokompozit',
        zpPrice: '75.00',
        price: '95.00'
      },
      {
        process: 'Rekonštrukcia korunky - priama',
        zpPrice: '110,00',
        price: '110.00'
      },
      {
        process: 'Paliatívne endodontické ošetrenie',
        zpPrice: '20.00',
        price: '30,00'
      },
      {
        process: 'Endodontické ošetrenie jednokoreňového zuba',
        zpPrice: '65.00',
        price: '95.00'
      },
      {
        process: 'Endodontické ošetrenie viackoreňového zuba',
        zpPrice: '80,00',
        price: '125.00'
      },
      {
        process: 'Endodontické ošetrenie za každy dalši kanálik',
        zpPrice: '20,00',
        price: '20.00'
      },
      {
        process: 'Nadštandart endo Meta System jedenokoreňový zub',
        zpPrice: '10.00',
        price: '10.00'
      },
      {
        process: 'Nadštandart endo Meta System viackoreňový zub',
        zpPrice: '20.00',
        price: '20.00'
      },
      {
        process: 'Strojová endodoncia MTwo-jednokoreňového zuba Ca(OH)2 vložka',
        zpPrice: '20.00',
        price: '20.00'
      },
      {
        process: 'Strojová endodoncia-MTwo-viackoreňového zuba Ca(OH)2 vložka',
        zpPrice: '30.00',
        price: '30.00'
      },
      {
        process: 'Reendodoncia jeden kanalik',
        zpPrice: '30.00',
        price: '30.00'
      },
      {
        process: 'Dočasná výplň',
        zpPrice: '20.00',
        price: '20.00'
      },
      {
        process: 'Dočasná fotokompozitná plomba',
        zpPrice: '25.00',
        price: '25.00'
      },
      {
        process: 'Odstránenie citlivosti zuba',
        zpPrice: '10.00',
        price: '10.00'
      },
      {
        process: 'Naloženie sep. matric',
        zpPrice: '5.00',
        price: '5.00'
      },
      {
        process: 'Odstránenie kameňa a povlaku',
        zpPrice: '35.00',
        price: '50.00'
      },
      {
        process: 'Odstránenie povlaku',
        zpPrice: '-',
        price: '20,00'
      },
      {
        process: 'Odstránenie starej vyplne',
        zpPrice: '15.00',
        price: '15.00'
      },
      {
        process: 'Odstránenie mäkkých tkanív',
        zpPrice: '15.00',
        price: '15.00'
      },
      {
        process: 'Podložka',
        zpPrice: '-',
        price: '5.00'
      },
      {
        process: 'Koferdam, roberdam',
        zpPrice: '5.00',
        price: '5.00'
      },
      {
        process: 'Plomba pod korunku',
        zpPrice: '20,00',
        price: '20,00'
      },
      {
        process: 'Sklovláknenný čap',
        zpPrice: '50,00',
        price: '50,00'
      },
      {
        process: 'Čap',
        zpPrice: '20,00',
        price: '20,00'
      },
      {
        process: 'Dlahovanie zubov sklenným vláknom',
        zpPrice: '80,00',
        price: '80,00'
      }
    ]
  },
  {
    title: 'Protetika',
    procedures: [
      {
        process: 'Odtlačok silikónový',
        zpPrice: '15.00',
        price: '15.00'
      },
      {
        process: 'Intraorálný sken zubov',
        zpPrice: '30.00',
        price: '30.00'
      },
      {
        process: 'Ind. lžica',
        zpPrice: '20.00',
        price: '20.00'
      },
      {
        process: 'Kovokeramická korunka',
        zpPrice: '200.00',
        price: '220.00'
      },
      {
        process: 'Zirkonoxid korunka',
        zpPrice: '270.00',
        price: '270.00'
      },
      {
        process: 'Čiastočná snímateľná náhrada - nadst.',
        zpPrice: '260.00',
        price: '350.00'
      },
      {
        process: 'Celková snímateľná náhrada - nadst.',
        zpPrice: '260.00',
        price: '350.00'
      },
      {
        process: 'Dočasná korunka',
        zpPrice: '10.00',
        price: '10.00'
      },
      {
        process: 'Dočasná korunka Lb',
        zpPrice: '20.00',
        price: '20.00'
      },
      {
        process: 'Nacementovanie mostíka',
        zpPrice: '10.00',
        price: '10.00'
      },
      {
        process: 'Nacementovanie mostíka po zar.',
        zpPrice: '20,00',
        price: '20,00'
      },
      {
        process: 'Cementovanie dočasnej korunky',
        zpPrice: '1,00',
        price: '1,00'
      },
      {
        process: 'Stiahnutie korunky',
        zpPrice: '5,00',
        price: '10,00'
      },
      {
        process: 'Termoplastická snímateľná náhrada - nadštand.',
        zpPrice: '330,00',
        price: '420,00'
      },
      {
        process: 'Protéza na zásuvných spojoch',
        zpPrice: '600,00',
        price: '600,00'
      }
    ]
  },
  {
    title: 'Chirurgia',
    procedures: [
      {
        process: 'Augmentácia',
        zpPrice: '-',
        price: '200,00'
      },
      {
        process: 'Sinus-lift zatvorený',
        zpPrice: '-',
        price: '350,00'
      },
      {
        process: 'Sinus-lift otvorený',
        zpPrice: '-',
        price: '500,00'
      },
      {
        process: 'Kosť Cera Bone 0,25',
        zpPrice: '-',
        price: '150,00'
      },
      {
        process: 'Kosť CeraBone 0.5g',
        zpPrice: '-',
        price: '200,00'
      },
      {
        process: 'Kosť CeraBone 1g',
        zpPrice: '-',
        price: '250,00'
      },
      {
        process: 'Inplantát',
        zpPrice: '-',
        price: '600,00'
      },
      {
        process: 'Inplantát Straumann',
        zpPrice: '-',
        price: '1200,00'
      },
      {
        process: 'Kryt na inplantát',
        zpPrice: '-',
        price: '50,00'
      },
      {
        process: 'Zavedenie inplantátu',
        zpPrice: '-',
        price: '50,00'
      },
      {
        process: 'Gingivoformer',
        zpPrice: '-',
        price: '50,00'
      },
      {
        process: 'Kovoker korunka na inplantát',
        zpPrice: '-',
        price: '250,00'
      },
      {
        process: 'Multiunit, abutment',
        zpPrice: '-',
        price: '180,00'
      },
      {
        process: 'Fixačná šrúba na korunku',
        zpPrice: '-',
        price: '50,00'
      },
      {
        process: 'Zirkonoxid korunka na inplantát',
        zpPrice: '-',
        price: '300,00'
      },
      {
        process: 'Resekcia koreň. hrotu',
        zpPrice: '-',
        price: '70,00'
      },
      {
        process: 'Retrogradne plombovanie',
        zpPrice: '-',
        price: '40,00'
      }
    ]
  }
];

