import {AfterViewInit, Component, HostListener, OnInit} from '@angular/core';
import M from 'materialize-css';
import {Router} from "@angular/router";
import {split} from "ts-node";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {

  constructor(public router: Router) {
  }

  activeRouteName = 'UVOD';
  responsive = false;
  showContactOnTop = false;

  @HostListener('window:resize', ['$event'])
  onResize(event): any {
    const width = +event.target.innerWidth;

    if (width < 1000) { // 768px portrait
      this.responsive = true;
      if (width < 860) {
        this.showContactOnTop = true;
      } else {
        this.showContactOnTop = false;
      }
    } else {
      this.responsive = false;
    }
  }

  ngOnInit(): void {
    this.onResize({target: {innerWidth: window.screen.width}});
  }

  ngAfterViewInit(): any {
    document.addEventListener('DOMContentLoaded', () => {
      const elems = document.querySelectorAll('.sidenav');
      const instances = M.Sidenav.init(elems, null);
    });
  }

  goHome(): void {
    this.router.navigate(['/home']);
  }
}
