<div class="container">
  <div class="sluzby-title">
    <div class="flex w-full justify-center title">
      <h4 style="text-transform: uppercase">Služby</h4>
    </div>
    <br>
    <div class="flex w-full justify-center">
      <!--      <p>Objednať sa u nás môžete <span class="red-text">mailom</span> alebo <span class="red-text">telefonicky</span>-->
      <!--        kedykoľvek počas pracovných hodín.</p>-->
    </div>
  </div>


  <div #babyTeeth class="section divid">
    <img style="float: right; margin-top: 2rem" src="../../assets/thumbs/baby-teeth.webp">
    <h2>Výplň detských zubov</h2>
    <p>Preventívne prehliadky v detskom veku vykonávame za účasti rodičov. Pri týchto
      prehliadkach sa sústredíme na vyhľadávanie zubných kazov, váčkov a iných ochorení,
      kladieme dôraz na prevenciu vzniku ortodontických anomálii a ich následkov.
    </p>
    <p>Niektoré
      zdanlivo banálne odchýlky môžu totiž, pokiaľ nie sú odhalené včas spôsobiť významnú
      disproporciu v raste čeľustí a zapríčiniť do budúcna dieťaťu len ťažko riešiteľnú
      ortodontickú vadu a neestetickú zmenu vzhľadu tváre. Pritom tieto odchýlky postihujúce
      iba skus jednotlivých zubov sú veľmi ľahko riešiteľné zábrusom hrbolku príslušného zubu.

    </p>
    <p>
      Pokiaľ sa problém podchytí včas, neprenáša sa ďalej do stáleho chrupu, a dieťa dokonca
      nepotrebuje nosiť ani ortodontický aparát.
    </p>
    <div class="flex w-full justify-start">
      <a (click)="openCennik()" class="waves-effect waves-light btn"><i class="material-icons left">euro_symbol</i>CENNÍK</a>
    </div>
  </div>

  <div #white class="section divid">
    <img style="float: right" src="../../assets/thumbs/white.webp">
    <h2>Bielenie zubov</h2>
    <p>Bielenie zubov patrí medzi najvyhľadávanejšie procedúry pacientov zubných kliník. A je to celkom pochopiteľné –
      belšie zuby sú
      rýchlou a zaručenou cestou ku krajšiemu a zdravšie pôsobiacemu úsmevu, ktorý vášmu okoliu o vás vysiela dôležitý
      signál. </p>
    <p>S krajším
      úsmevom sa prirodzene zvyšuje aj vaše sebavedomie – častejšie a srdečnejšie sa smejete a do života si tak vpúšťate
      viac radosti a
      šťastia. Biele zuby skutočne vedia urobiť vo vašom živote zásadnú zmenu k lepšiemu. </p>
    <div class="flex w-full justify-start">
      <a (click)="openCennik()" class="waves-effect waves-light btn"><i class="material-icons left">euro_symbol</i>CENNÍK</a>
    </div>
  </div>

  <div #pineTeeth class="section divid">
    <img style="float: right" src="../../assets/thumbs/pain-teeth.webp">
    <h2>Ošetrenie bolestivých zubov</h2>
    <p>Naša zubná klinika poskytuje komplexné ošetrenie pre pacientov, ktorí zažívajú bolesti alebo nepohodlie v oblasti
      zubov.
      Naši skúsení zubní lekári využívajú najmodernejšie diagnostické metódy a techniky, aby rýchlo identifikovali
      príčinu bolesti a navrhli vhodnú liečbu.

    </p>
    <p>
      Či už ide o zápal, kaz, infekciu alebo iný problém, sme tu, aby sme vám pomohli vrátiť sa k bežnému životu bez
      bolesti. Vždy sa snažíme ponúknuť
      okamžitú pomoc a zabezpečiť, aby vaša návšteva u nás bola čo najpríjemnejšia.</p>
    <div class="flex w-full justify-start">
      <a (click)="openCennik()" class="waves-effect waves-light btn"><i class="material-icons left">euro_symbol</i>CENNÍK</a>
    </div>
  </div>

  <div #implement class="section divid">
    <img style="float: right" src="../../assets/thumbs/implement.webp">
    <h2>Zubné implantáty</h2>
    <p>Zubné implantáty predstavujú moderné a dlhotrvajúce riešenie pre náhradu chýbajúcich zubov.</p>
    <p>Ide o malé titánové skrutky, ktoré sa chirurgicky umiestňujú do čeľuste a slúžia ako náhrada za korene
      chýbajúcich zubov.
      Po zahojení sa na implantáty môže pripevniť korunka, mostík alebo dokonca celá protéza.</p>
    <div class="flex w-full justify-end">
      <a (click)="openCennik()" class="waves-effect waves-light btn"><i class="material-icons left">euro_symbol</i>CENNÍK</a>
    </div>
  </div>

  <div #dentalFilling class="section divid">
    <img style="float: left" src="../../assets/thumbs/dental-filling.webp">
    <h2>Zubné výplne</h2>
    <p>
      Naša zubná klinika ponúka služby v oblasti zubných výplní pre pacientov,
      ktorí potrebujú opravit poškodený alebo kazom postihnutý zub.
    </p>
    <p>
      Používame vysokokvalitné materiály a najnovšie technológie, aby sme zaistili dlhotrvajúce a esteticky príjemné
      výsledky.
      Naši odborníci dbajú na to, aby aplikácia výplne bola čo najmenej bolestivá a komfortná pre pacienta.
      Bez ohľadu na to, či potrebujete jednoduchú amalgámovú výplň alebo kompozitnú výplň v prírodnej farbe zuba,
      sme pripravení poskytnúť vám kvalitnú starostlivosť, ktorá obnoví zdravie a funkciu vášho zuba.
    </p>
    <div class="flex w-full justify-end">
      <a (click)="openCennik()" class="waves-effect waves-light btn"><i class="material-icons left">euro_symbol</i>CENNÍK</a>
    </div>
  </div>

  <div #sedation class="section">
    <img style="float: right" class="pl-24" src="../../assets/thumbs/sedation.webp">
    <h2>Anesteziológické ošetrenie</h2>
    <p>
      Naša zubná ambulancia je vybavená modernými anesteziologickými riešeniami,
      ktoré zabezpečujú, aby boli všetky naše zákroky pre pacientov čo najbezbolestnejšie.</p>
    <p>
    <p>
      Naši skúsení anesteziológovia pracujú v tesnej spolupráci s tímom zubných lekárov,
      aby garantovali bezpečné a efektívne uplatnenie lokálnej alebo všeobecnej anestézie podľa potreby zákroku.
      Či už ide o jednoduchý extrakčný zákrok alebo komplexnejšiu chirurgiu, prioritou je komfort a bezpečnosť pacienta.
      Využívame najnovšie metódy a technológie, aby sme minimalizovali vedľajšie účinky a zabezpečili rýchle zotavenie
      po anestézii.
      Naším cieľom je, aby vaša návšteva u nás prebehla hladko a bez obáv.
    </p>
    <div class="flex w-full justify-end">
      <a (click)="openCennik()" class="waves-effect waves-light btn"><i class="material-icons left">euro_symbol</i>CENNÍK</a>
    </div>
  </div>
</div>
