<div class="container">
  <div class="contact-title">
    <div class="flex w-full justify-center title">
      <h4 style="text-transform: uppercase">Kontaktné Informácie</h4>
    </div>
    <br>
    <div class="flex w-full justify-center">
      <p>Objednať sa u nás môžete <span class="red-text">mailom</span> alebo <span class="red-text">telefonicky</span>
        kedykoľvek počas pracovných hodín.</p>
    </div>
  </div>


  <div class="contact-information row">
    <div class="contact-box col m6 s12">
      <h2 class="contact-box-title">
        <mat-icon matPrefix style="padding-right: 2rem">contacts</mat-icon>
        Kontaktné informácie:
      </h2>
      <div class="contact justify-start flex align-center pt-24">
        <div class="col">
          <i class="material-icons icon small">phone</i>
        </div>
        <div class="col" style="font-weight: bold">
          <div class="flex grow-1">
            <a href="tel:0911970706"><span class="contact__text">0911 970 706</span></a>
          </div>
        </div>
      </div>
      <div class="contact justify-start flex align-center pt-24">
        <div class="col">
          <i class="material-icons icon small">drafts</i>
        </div>
        <div class="col" style="font-weight: bold">
          <div class="flex grow-1">
            <a href="mailto:tarasdentsro@gmail.com"><span class="contact__text">tarasdentsro@gmail.com</span></a>
          </div>
        </div>
      </div>
      <br>
      <div class="contact justify-start flex align-center ">
        <div class="col" style="font-weight: bold">
          <i class="material-icons icon small">facebook</i>
        </div>
        <div class="col" style="font-weight: bold">
          <div class="flex grow-1">
            <a href="https://www.facebook.com/" target="_blank"><span class="contact__text">TARASDENT, s.r.o</span></a>
          </div>
        </div>
      </div>
      <br>
      <div class="contact justify-start flex align-center">
        <div class="col">
          <i class="material-icons icon small">home</i>
        </div>
        <div class="col" style="font-weight: bold">
          <div class="flex grow-1">
            <a href="https://maps.app.goo.gl/kixodihx6Z6R1t698" target="_blank"
            ><span class="contact__text">Nové Mesto nad Váhom<br><span class="right">1. mája 9</span></span></a>
          </div>
        </div>
      </div>
    </div>
    <div class="col m6 s12">
      <div class="margin-to-mid">
        <h2 class="open-hour-title">
          <mat-icon matPrefix style="padding-right: 2rem">schedule</mat-icon>
          Otváracie hodiny:
        </h2>
        <div class="row m-0">
          <div class="col s4"><h3><b>Pondelok:</b></h3>
          </div>
          <div class="col s6"><h3 style="">8:00 - 14:30</h3></div>
        </div>
        <div class="row m-0">
          <div class="col s4"><h3><b>Utorok:</b></h3>
          </div>
          <div class="col s6"><h3 style="">8:00 - 14:30</h3></div>
        </div>
        <div class="row m-0">
          <div class="col s4"><h3><b>Streda:</b></h3>
          </div>
          <div class="col s6"><h3 style="">11:00 - 17:30</h3></div>
        </div>
        <div class="row m-0">
          <div class="col s4"><h3><b>Štvrtok:</b></h3>
          </div>
          <div class="col s6"><h3 style="">8:00 - 14:30</h3></div>
        </div>
        <div class="row m-0">
          <div class="col s4"><h3><b>Piatok:</b></h3>
          </div>
          <div class="col s6"><h3 style="">8:00 - 12:00</h3></div>
        </div>
        <div class="row m-0">
          <div class="col s4"><h3><b>Sobota:</b></h3>
          </div>
          <div class="col s6 text-red"><h3 style="">Zatvorené</h3></div>
        </div>
        <div class="row m-0">
          <div class="col s4"><h3><b>Nedeľa:</b></h3>
          </div>
          <div class="col s6 text-red"><h3 style="">Zatvorené</h3></div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex w-full mt-12 justify-center">
    <h2 class="map-title">
      <mat-icon matPrefix style="padding-right: 2rem">place</mat-icon>
      Kde nás nájdete: <a href="https://maps.app.goo.gl/kixodihx6Z6R1t698" target="_blank"
    >Nové Mesto nad Váhom - 1. mája 9</a>
    </h2>
  </div>
  <div class="flex w-full justify-center mb-48  ">
    <agm-map [latitude]="lat" [longitude]="lng" [zoom]="19"></agm-map>
    <br>
  </div>
</div>
