import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LandingPageComponent} from './landing-page/landing-page.component';
import {KontaktComponent} from './kontakt/kontakt.component';
import {SluzbyComponent} from './sluzby/sluzby.component';
import {CennikComponent} from './cennik/cennik.component';
import {TechnologieComponent} from './technologie/technologie.component';

const routes: Routes = [
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {path: '*', redirectTo: 'home', pathMatch: 'full'},
  {path: 'home', component: LandingPageComponent},
  {path: 'kontakt', component: KontaktComponent},
  {path: 'sluzby', component: SluzbyComponent},
  {path: 'cennik', component: CennikComponent},
  {path: 'technologie', component: TechnologieComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
