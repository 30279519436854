<section>
  <div class="header row">
    <div id="logo_section" class="col s12 m6">
      <div class="flex grow-1 justify-start pl-48 pt-8 pb-8">
<!--        <img class="pr-16" style="height: 3rem" src="/assets/logo/logo.png" alt="logo">-->
<!--        <img style="height: 3rem" src="assets/logo/logo_title.png" alt="logo_title">-->
        <img style="height: 6em" src="assets/logo/logo_no_back_2.pdn" alt="logo_title">
      </div>
    </div>
    <div *ngIf="!showContactOnTop" class="col s12 m6">
      <div class="grow-1 w-full row">
        <div class="top-contact pointer col m4 justify-end flex align-center pt-32">
          <div class="col">
            <i class="material-icons icon">phone</i>
          </div>
          <div class="col" style="font-weight: bold">
            <div class="flex grow-1">
              <a href="tel:0911970706">0911 970 706</a>
            </div>
          </div>
        </div>
        <div class="top-contact pointer col m5 justify-center flex align-center pt-24">
          <div class="col">
            <i class="material-icons icon">home</i>
          </div>
          <div class="col">
            <div class="flex grow-1">
              <a href="https://maps.app.goo.gl/kixodihx6Z6R1t698" style="font-weight: bold" target="_blank"
              >Nové Mesto nad Váhom<br><span class="left">1. mája 9</span></a>
            </div>
          </div>
        </div>

        <div class="top-contact pointer col m3 justify-start flex align-center pt-32">
          <div class="col">
            <i class="material-icons icon">facebook</i>
          </div>
          <div class="col">
            <div class="flex grow-1">
              <a href="https://www.facebook.com/" target="_blank" style="font-weight: bold">TARASDENT, s.r.o</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="navigation">
    <nav>
      <div class="nav-wrapper">
        <a *ngIf="responsive" (click)="goHome()" class="pl-48 font-size-20">Domov</a>
        <a href="#" data-target="mobile-demo" class="sidenav-trigger right pl-48"><i class="material-icons">menu</i></a>

        <ul class="flex w-full hide-on-med-and-down">
          <div class="justify-start">
            <li style="width: 5vh"></li>
            <li><a routerLink="home" routerLinkActive="active">ÚVOD</a></li>
<!--            <li><a class="disabled" routerLinkActive="active">TECHNOLÓGIE</a></li>-->
            <li><a routerLink="sluzby" routerLinkActive="active">SLUŽBY</a></li>
            <li><a routerLink="cennik" routerLinkActive="active">CENNÍK</a></li>
            <li><a routerLink="kontakt" routerLinkActive="active">KONTAKT</a></li>
          </div>

          <div class="language flex grow-1 justify-end">
<!--            <li><a class="active-lan">SVK</a></li>-->
<!--            <li><a>ENG</a></li>-->
            <li></li>
          </div>
        </ul>
      </div>
    </nav>

    <ul class="mobile-menu sidenav text-right" id="mobile-demo">
      <li><a class="text-red" routerLink="home">ÚVOD</a></li>
      <li><a routerLink="sluzby">SLUŽBY</a></li>
      <li><a routerLink="cennik">CENNÍK</a></li>
      <li><a routerLink="kontakt">KONTAKT</a></li>
    </ul>
  </div>
</section>
