import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import * as cennikData from './cennik';

@Component({
  selector: 'app-cennik',
  templateUrl: './cennik.component.html',
  styleUrls: ['./cennik.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CennikComponent implements OnInit, AfterViewInit {

  cennik = cennikData.cennik;
  selectedIndex = 0;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // Timeout is used to delay the execution, ensuring the element is fully rendered
    setTimeout(() => {
      const paginationElemAfter = this.el.nativeElement.querySelector('.mat-tab-header-pagination-after');
      const paginationElemBefore = this.el.nativeElement.querySelector('.mat-tab-header-pagination-before');

      this.renderer.listen(paginationElemAfter, 'click', (event) => {
        if (this.selectedIndex < 4) {
          this.selectedIndex = this.selectedIndex + 1;
        }
      });

      this.renderer.listen(paginationElemBefore, 'click', (event) => {
        if (this.selectedIndex > 0) {
          this.selectedIndex = this.selectedIndex - 1;
        }
      });
    });
  }
}
